import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

import { palette } from '@system/theme/palette';

export const MuiAlert: Components<Theme>['MuiAlert'] = {
  styleOverrides: {
    filledSuccess: {
      backgroundColor: palette.success.main,
      color: palette.common.white,
    },
    filledError: {
      backgroundColor: palette.error.main,
    },
    filledWarning: {
      backgroundColor: palette.customs.bus,
    },
  },
};
