import { createElement } from 'react';

import { checkboxClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

import { CheckBoxIcon } from '@components/icons/CheckBox';
import { CheckBoxCheckedIcon } from '@components/icons/CheckBoxChecked';

import { palette } from '@system/theme/palette';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  defaultProps: {
    icon: createElement(CheckBoxIcon),
    checkedIcon: createElement(CheckBoxCheckedIcon),
  },
  styleOverrides: {
    root: {
      color: palette.border.stroke,
      '&:hover': {
        color: palette.secondary.main,
        backgroundColor: 'transparent',
      },
    },
    colorPrimary: {
      [`&.${checkboxClasses.checked}`]: {
        color: palette.secondary.main,
      },
      [`&.${checkboxClasses.checked}:hover`]: {
        color: palette.secondary.dark,
      },
      [`&.${checkboxClasses.disabled}`]: {
        color: palette.secondary.light,
      },
    },
  },
};
